.source-code pro-font {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.bash {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  font-size: 35px;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  white-space: pre-wrap;
}

.card-writer {
  position: absolute;
  text-align: left;
  font-size: 110%;
  color: rgb(0, 0, 0);
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  white-space: pre-wrap;
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 40%;
}

body {
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotateX(30deg);
  }
  100% {
    transform: rotateX(-15deg);
  }
}

.card-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 450px;
  height: 620px;
  animation: fade-in 2s 2.2s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 500px) {
  .card-container {
    width: 300px; /* mobile */
    height: 400px;
  }

  .card-writer {
    font-size: 70%;
  }
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  filter: blur(0.5px) grayscale(70%);
  box-shadow: 0px 25px 60px rgba(106, 109, 109, 0.704);
  background-color: #ffffeba2;
  perspective: 10rem;
  animation: rotate 2s 2.2s;
  transform-style: preserve-3d;
  transition: all 0.8s ease;
  transform: rotateX(-15deg);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.card:hover {
  filter: none;
  transform: rotateX(0deg);
  box-shadow: 0px 5px 10px rgba(137, 142, 142, 0.837);
}

.img {
  margin-top: 4%;
  position: absolute;
  max-width: 60%;
  height: auto;
  top: 0%;
}

.links {
  position: relative;
  top: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  width: 80%;
}

.link {
  width: 50%;
  height: 50%;
  filter: grayscale(85%);
  transition: filter 0.8s ease;
}

.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link:hover {
  transition: filter 0.8s ease;
  filter: grayscale(0%);
}
